<template>
    
    <div class="h-full w-full flex flex-col">
        <div class="h-10 flex flex-row justify-start items-center px-2 flex-none">
            <!-- <div class="h-5 w-5 bg-filters rounded-full mr-4 flex flex-col justify-center items-center"
                 @click="$router.go(-1)" >
                <i class="mdi mdi-chevron-left text-aux text-lg"></i>
            </div> -->
            <span class="text-aux font-semibold text-lg">Interoperabilidad</span>
        </div>
        <transition
            name="fade"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
            mode="out-in">
            <router-view @setSL="setSL" @options="options" :sl="sl" :options="activeOptions" />
        </transition>
    </div>

</template>

<script>
export default {
    data() {
        return {
            sl: null,
            activeOptions: []
        }
    },
    methods: {
        setSL(sl) { this.sl = sl },
        options(options) { this.activeOptions = options }
    }
}
</script>